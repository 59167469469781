import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import React, { FC, useState } from 'react';

import PaymentAccept from '@/components/checkout-form/accept-payments/PaymentAccept';
import { Image } from '@/components/image';
import { Skeleton } from '@/components/ui/loaders/Skeletons/Skeletons';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useTypedSelector } from '@/hooks/useTypedSelector';

import { useGetCatalogueMenuQuery } from '@/store/api/catalogue.api';
import { switchModal } from '@/store/slices/individual-order-modal/individualOrderModal.slice';
import { changeModalVisibility } from '@/store/slices/support-modal/support-modal.slice';

import { IProductRubric } from '@/types/new/products';

import { ROUTES } from '@/constants/routes.constant';

import EnterProfile from '../../enter-profile/EnterProfile';
import Social from '../social/Social';

import styles from './MobileNavigation.module.scss';
import { CallUs } from './call-us/CallUs';

const ChevronIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.00005 9.72375L3.13812 4.86182L2.19531 5.80463L8.00005 11.6094L13.8048 5.80463L12.862 4.86182L8.00005 9.72375Z" />
    </svg>
  );
};

const Navigation: FC<{
  onClose: () => void;
  categories: IProductRubric[] | undefined;
  loading: boolean;
}> = ({ onClose }) => {
  const { data: catalogue, isLoading } = useGetCatalogueMenuQuery();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const { isOpen } = useTypedSelector((state) => state.individualOrderModal);

  const dispatch = useAppDispatch();

  function onMenuClick(index: number) {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  }

  const menu = [
    { text: 'О нас', href: ROUTES.ABOUT_US },
    { text: 'Доставка', href: ROUTES.DELIVERY },
    { text: 'Оплата', href: ROUTES.PAYMENT },
    { text: 'Бонусная программа', href: ROUTES.BONUS_PROGRAM },
    { text: 'Отзывы', href: ROUTES.REVIEWS },
    { text: 'Контакты', href: ROUTES.CONTACTS },
    { text: 'Поддержка', onClick: () => dispatch(changeModalVisibility(true)) },
    { text: 'Корпоративным клиентам', href: ROUTES.KORPORATIVNYM_KLIENTAM },
    { text: 'Блог', href: ROUTES.BLOG },
  ];

  return (
    <motion.div className={styles.wrapper}>
      <AnimatePresence initial={false}>
        <EnterProfile key="profile" onClose={onClose} />

        <div className={styles.mobile_container} key="menu">
          <h3 className={styles.heading}>Каталог:</h3>

          <ul className={styles.ul_wrapper}>
            {isLoading
              ? [...new Array(6)].map((_, index) => (
                  <li key={index}>
                    <Skeleton className={styles.skeleton} />
                  </li>
                ))
              : catalogue?.menu.map(({ icon, submenu, href, title }, index) => (
                  <li
                    key={title}
                    className={clsx(styles.menuItem, activeIndex === index && styles.active)}
                    onClick={() => onMenuClick(index)}
                  >
                    <div className={styles.header}>
                      <Image
                        src={`/static/icons/header/${icon}.png`}
                        width={24}
                        height={24}
                        alt={title}
                      />

                      {href ? (
                        <Link href={href} className={styles.link} onClick={onClose}>
                          {title}
                        </Link>
                      ) : (
                        <span className={styles.link}>{title}</span>
                      )}

                      {!href && (
                        <div
                          className={clsx(styles.shevron, activeIndex === index && styles.active)}
                        >
                          <ChevronIcon />
                        </div>
                      )}
                    </div>

                    {activeIndex === index && !!submenu.length && (
                      <ol className={clsx(styles.submenu)}>
                        {submenu.map(({ href, slug, name }, index) => (
                          <li key={slug}>
                            <Link href={href} onClick={onClose}>
                              <b>{index + 1}</b>
                              {name}
                            </Link>
                          </li>
                        ))}
                      </ol>
                    )}
                  </li>
                ))}

            <li onClick={() => dispatch(switchModal(!isOpen))}>
              <a className={styles.by_photo}>
                <Image
                  src={`/static/icons/header/folder-photo.svg`}
                  width={16}
                  height={16}
                  alt="Букет по фото"
                />
                <span>Букет по фото</span>
              </a>
            </li>
            <li className={styles.all}>
              <Link
                href="/catalog"
                className={clsx(styles.link, styles.link_all)}
                onClick={onClose}
              >
                <span style={{ color: '#ff0031', fontWeight: 500 }}>Весь каталог</span>
              </Link>
            </li>

            <div className={styles[`colored-section`]}>
              <nav className={styles.mobile__drawer_nav}>
                <ul className={styles.mobile__drawer_nav__list}>
                  <div className={styles.mobile__drawer_nav__title}>Помощь покупателям:</div>
                  {/* //TODO разобраться с этим конфигом меню */}
                  {menu.map((el) => {
                    if (el.onClick)
                      return (
                        <li key={el.text}>
                          <button
                            type="button"
                            className={styles.mobile__drawer_nav__link}
                            onClick={el.onClick}
                          >
                            {el.text}
                          </button>
                        </li>
                      );

                    if (el.href)
                      return (
                        <li key={el.href}>
                          <Link
                            href={el.href}
                            className={styles.mobile__drawer_nav__link}
                            onClick={onClose}
                          >
                            {el.text}
                          </Link>
                        </li>
                      );
                  })}
                </ul>
              </nav>

              <CallUs />

              <PaymentAccept />
              <Social />
            </div>
          </ul>
        </div>
      </AnimatePresence>
    </motion.div>
  );
};

export default Navigation;
