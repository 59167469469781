import clsx from 'clsx';
import Link from 'next/link';
import { HTMLAttributes, ReactNode, useMemo, useState } from 'react';

import { TMenu } from '@/components/layout/footer/footer.data';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { changeModalVisibility } from '@/store/slices/support-modal/support-modal.slice';

import styles from './MenuList.module.scss';

type Props = HTMLAttributes<HTMLDivElement> &
  TMenu & {
    mode?: 'accordion' | 'default';
  };

const ItemBody = ({ icon, title }: { icon?: ReactNode; title: string }) => (
  <>
    {icon && <span className={styles.icon}>{icon}</span>}
    <span className={styles.title}>{title}</span>
  </>
);

const ChevronDown = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M12.6673 6L8.00065 10L3.33398 6"
      stroke="#969696"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MenuList = ({ title, items, mode = 'default', className, ...rest }: Props) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const HeaderMap = useMemo(
    () => ({
      accordion: (
        <button
          className={clsx(styles.accordionButton, isActive && styles.active)}
          onClick={() => setIsActive(!isActive)}
        >
          {title}
          {ChevronDown}
        </button>
      ),
      default: <span className={clsx(styles.mainTitle)}>{title}</span>,
    }),
    [isActive]
  );

  const ActionsMap: Record<NonNullable<TMenu['items'][0]['action']>, () => void> = {
    supportModal: onSupportClick,
  };

  function onSupportClick() {
    dispatch(changeModalVisibility(true));
  }

  return (
    <div className={clsx(styles.wrapper, className)} {...rest}>
      {HeaderMap[mode]}

      {mode !== 'accordion' && (
        <ul className={styles.list}>
          {items.map((el) => (
            <li key={el.title} className={styles.listItem}>
              {el.action && (
                <button onClick={ActionsMap[el.action]}>
                  <ItemBody icon={el.icon} title={el.title} />
                </button>
              )}
              {el.href && (
                <Link href={el.href} target={el.target} prefetch={false}>
                  <ItemBody icon={el.icon} title={el.title} />
                </Link>
              )}
            </li>
          ))}
        </ul>
      )}

      {mode === 'accordion' && isActive && (
        <ul className={styles.list}>
          {items.map((el) => (
            <li key={el.title} className={styles.listItem}>
              {el.action && (
                <button onClick={ActionsMap[el.action]}>
                  <ItemBody icon={el.icon} title={el.title} />
                </button>
              )}
              {el.href && (
                <Link href={el.href} target={el.target} prefetch={false}>
                  <ItemBody icon={el.icon} title={el.title} />
                </Link>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
