import clsx from 'clsx';
import dynamic from 'next/dynamic';
import React, { FC, HTMLAttributes, PropsWithChildren, useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { Container } from '@/components/container';
import { CustomDrawer } from '@/components/layout/header/custom-drawer';
import MobileDrawer from '@/components/layout/header/mobile-drawer/MobileDrawer';

import { jivoActions } from '@/helpers/jivoActions';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import { useUtmSave } from '@/hooks/useUtmSave';

import { useGetLastActivePromotionQuery } from '@/store/api/promotions.api';
import { closeDrawer } from '@/store/slices/drawer/drawer.slice';

import Footer from '../footer/Footer';
import { Header } from '../header';

import styles from './MainLayout.module.scss';

const MobileNavigation = dynamic(
  () => import('@/components/layout/mobile-navigation/MobileNavigation')
);

const ErrorFallback = () => {
  return (
    <Container className={styles.errorBoundary}>
      <h2>При загрузке страницы что-то пошло не так</h2>
      <p>Пожалуйста, обновите страницу или свяжитесь с поддержкой</p>
      <button onClick={() => jivoActions.open()}>Открыть чат с поддержкой</button>
    </Container>
  );
};

const MainLayout: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({
  children,
  className,
}) => {
  const isTablet = useMediaQuery('1100px');

  const isOpen = useTypedSelector((state) => state.drawer.isOpen);
  const dispatch = useAppDispatch();

  const onClose = useCallback(() => {
    dispatch(closeDrawer());
  }, []);

  useUtmSave();
  useGetLastActivePromotionQuery();

  return (
    <div className={clsx(className, styles.wrapper)}>
      <Header />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <main className={styles.main}>
          {isTablet ? (
            <MobileDrawer isOpen={isOpen} onClose={onClose} />
          ) : (
            <CustomDrawer isOpen={isOpen} onClose={onClose} />
          )}
          {children}
        </main>
      </ErrorBoundary>
      <Footer />
      <MobileNavigation />
    </div>
  );
};

export default MainLayout;
