import clsx from 'clsx';
import { HTMLAttributes } from 'react';

import styles from './PaymentParts.module.scss';

type Props = HTMLAttributes<HTMLDivElement> & {};

export const paymentData = [
  { icon: 'podeli', alt: 'Принимается оплата через Подели' },
  { icon: 'dolyame', alt: 'Принимается оплата через Долями' },
  { icon: 'yandex-split', alt: 'Принимается оплата через Яндекс.Сплит' },
];

export const PaymentParts = ({ className, ...rest }: Props) => {
  return (
    <div className={clsx(styles.wrapper, className)} {...rest}>
      <span className={styles.mainTitle}>Плати частями</span>
      <ul className={styles.list}>
        {paymentData.map((item, i) => (
          <li key={i}>
            <img
              src={`/static/icons/accept-payments/${item.icon}.svg`}
              loading="lazy"
              decoding="async"
              alt={item.alt}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
