import { useRouter } from 'next/router';

import { Image } from '@/components/image';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useTypedSelector } from '@/hooks/useTypedSelector';

import { changeLoginPage } from '@/store/slices/login-user/login-user.slice';

import HeaderCities from '../header-cities/HeaderCities';

import styles from './EnterProfile.module.scss';

function EnterProfile({ onClose }: { onClose: () => void }) {
  const { login, userName } = useTypedSelector((state) => state.user);
  const router = useRouter();
  const dispatch = useAppDispatch();

  const openLoginModal = () => dispatch(changeLoginPage(1));

  async function onAuthClick() {
    if (login) {
      await router.push('/cabinet/profile');
    } else {
      openLoginModal();
    }

    onClose();
  }

  return (
    <section className={styles.enter_profile}>
      {!login && <h2 className={styles.enter_profile__heading}>Войдите в профиль</h2>}
      <div className={styles.enter_profile__text_wrapper}>
        <Image
          src={`/static/videos/azaliaicon.gif`}
          width={44}
          height={44}
          alt="Гиф изображение Азалия Коина"
        />
        {login && <h2 className={styles.enter_profile__heading}>{userName ?? 'Имя не указано'}</h2>}

        {!login && (
          <p className={styles.enter_profile__text}>
            И вы начнете получать бонусные Азалия Коины за покупки
          </p>
        )}
      </div>
      {login && (
        <div>
          <button className={styles.enter_profile__link_flex} onClick={onAuthClick}>
            <span className={styles.enter_profile__link_logined}>Перейти в профиль</span>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.72326 6.00005L0.861328 1.13812L1.80414 0.195312L7.60888 6.00005L1.80414 11.8048L0.861328 10.862L5.72326 6.00005Z"
                fill="#6B6B6B"
              />
            </svg>
          </button>{' '}
        </div>
      )}
      {!login && (
        <button className={styles.enter_profile__link} onClick={onAuthClick}>
          Войти / Регистрация
        </button>
      )}
      <HeaderCities noIcon />
    </section>
  );
}

export default EnterProfile;
