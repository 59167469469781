import clsx from 'clsx';
import { HTMLAttributes } from 'react';

import { formatPhoneNumber } from '@/helpers/string/formatPhoneNumber';

import { SOCIAL_LINKS } from '@/constants/social.constant';

import styles from './CallUs.module.scss';

interface CallUsProps extends HTMLAttributes<HTMLDivElement> {}

const PhoneIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
    <path
      d="M18.2627 22.926C19.8008 22.926 20.8467 22.4865 21.7959 21.4582C21.8574 21.3879 21.9189 21.3088 21.9805 21.2385C22.499 20.6584 22.7539 20.052 22.7539 19.4895C22.7539 18.8303 22.4023 18.2326 21.6201 17.6965L19.001 15.8684C18.1748 15.2971 17.2168 15.2531 16.4785 15.9914L15.8281 16.6418C15.5996 16.8703 15.3799 16.8879 15.1162 16.7033C14.6416 16.3869 13.7451 15.5959 13.1299 14.9807C12.5059 14.3567 11.873 13.6535 11.5303 13.1174C11.3457 12.8449 11.3633 12.634 11.5918 12.4055L12.2422 11.7551C12.9805 11.0168 12.9365 10.0588 12.3564 9.23264L10.4932 6.56956C9.97461 5.8137 9.35059 5.46213 8.69141 5.45334C8.12891 5.44456 7.53125 5.69944 6.95117 6.2092C6.88086 6.27073 6.81055 6.33225 6.74023 6.39377C5.69434 7.35178 5.24609 8.3801 5.24609 9.97971C5.24609 12.5725 6.74902 15.6311 9.67578 18.5403C12.585 21.4319 15.6084 22.926 18.2627 22.926ZM18.2627 21.4582C16.0215 21.4934 13.2354 19.9026 10.792 17.4768C8.33105 15.051 6.69629 12.1594 6.73145 9.91819C6.74902 8.97776 7.08301 8.15159 7.74219 7.57151C7.8125 7.51877 7.86523 7.47483 7.93555 7.42209C8.18164 7.21116 8.44531 7.0969 8.69141 7.0969C8.94629 7.0969 9.1748 7.20237 9.35059 7.46604L10.9941 9.93577C11.2051 10.2346 11.2139 10.5774 10.9062 10.8762L10.2031 11.5705C9.53516 12.2209 9.59668 12.9856 10.0186 13.5832C10.4932 14.2424 11.3809 15.2531 12.1279 15.9914C12.9277 16.7824 14.0176 17.758 14.6504 18.2151C15.248 18.6369 16.0127 18.6985 16.6631 18.0305L17.3574 17.3274C17.6475 17.0198 17.9902 17.0285 18.2979 17.2307L20.7236 18.8479C20.9961 19.0237 21.0928 19.2434 21.0928 19.4983C21.0928 19.7444 20.9785 20.008 20.7676 20.2629C20.7148 20.3244 20.6709 20.3772 20.6182 20.4475C20.0381 21.1155 19.2119 21.4494 18.2627 21.4582Z"
      fill="#28293D"
    />
  </svg>
);

export const CallUs = ({ className }: CallUsProps) => {
  return (
    <div className={clsx(styles.wrapper, className)}>
      <div className={styles.icon}>{PhoneIcon}</div>
      <div className={styles.body}>
        <a
          href={`tel:${formatPhoneNumber(SOCIAL_LINKS.main_phone_number)}`}
          className={styles.phone}
        >
          {formatPhoneNumber(SOCIAL_LINKS.main_phone_number, { mode: 'onlySpaces' })}
        </a>
        <a
          href={`tel:${formatPhoneNumber(SOCIAL_LINKS.additional_phone_number)}`}
          className={styles.phone}
        >
          {formatPhoneNumber(SOCIAL_LINKS.additional_phone_number, { mode: 'onlySpaces' })}
        </a>
        <div className={styles.description}>Если занято, мы перезвоним</div>
      </div>
    </div>
  );
};
