import clsx from 'clsx';
import { HTMLAttributes, useState } from 'react';

import styles from './PaymentMethods.module.scss';

type Props = HTMLAttributes<HTMLDivElement> & {
  isMobile?: boolean;
};

export const paymentData = [
  { icon: 'yandex', alt: 'Принимается оплата через YandexPay' },
  { icon: 'sbp', alt: 'Принимается оплата через СБП' },
  { icon: 't-pay', alt: 'Принимается оплата через T-Pay' },
  { icon: 'alfa', alt: 'Принимается оплата через Альфа-банк' },
  { icon: 'visa', alt: 'Принимается оплата через VISA' },
  { icon: 'mastercard', alt: 'Принимается оплата через MasterCard' },
  { icon: 'vtb', alt: 'Принимается оплата через ВТБ банк' },
  { icon: 'rosselhoz', alt: 'Принимается оплата через Россельхоз банк' },
  { icon: 'halva', alt: 'Принимается оплата через Халву' },
  { icon: 'sber', alt: 'Принимается оплата через СБП' },
  { icon: 'otkritie', alt: 'Принимается оплата через Банк Открытие' },
  { icon: 't-Bank', alt: 'Принимается оплата через T-Банк' },
  { icon: 'mir', alt: 'Принимается оплата через MIR' },
  { icon: 'psb', alt: 'Принимается оплата через Промсвязь банк' },
  { icon: 'smp', alt: 'Принимается оплата через СМП банк' },
  { icon: 'sovkombank', alt: 'Принимается оплата через Совкомбанк' },
  { icon: 'raifaisen', alt: 'Принимается оплата через Райффайзенбанк' },
];

const ChevronDown = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M12.6673 6L8.00065 10L3.33398 6"
      stroke="#35B447"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PaymentMethods = ({ className, isMobile, ...rest }: Props) => {
  const [items] = useState<typeof paymentData>(paymentData);
  const [isActive, setIsActive] = useState<boolean>(false);
  const toggleActive = () => {
    setIsActive(!isActive);
  };

  const displayItems = isMobile ? (isActive ? items : items.slice(0, 8)) : items;

  return (
    <div className={clsx(styles.wrapper, className)} {...rest}>
      <div className={styles.header}>
        <span className={styles.mainTitle}>Принимаем оплату</span>
        {isMobile && (
          <button
            className={clsx(styles.accordionButton, isActive && styles.active)}
            onClick={toggleActive}
          >
            {isActive ? 'Меньше' : 'Больше'}
            {ChevronDown}
          </button>
        )}
      </div>
      <ul className={styles.list}>
        {displayItems.map((item, i) => (
          <li key={i}>
            <img
              src={`/static/icons/accept-payments/${item.icon}.svg`}
              loading="lazy"
              decoding="async"
              alt={item.alt}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
