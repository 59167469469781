import clsx from 'clsx';
import { FC } from 'react';

import { Image } from '@/components/image';

import styles from './PaymentAccept.module.scss';

export const paymentData = [
  { icon: 'yandex', alt: 'Принимается оплата через YandexPay' },
  { icon: 'sbp', alt: 'Принимается оплата через СБП' },
  { icon: 't-pay', alt: 'Принимается оплата через T-Pay' },
  { icon: 'alfa', alt: 'Принимается оплата через Альфа-банк' },
  { icon: 'visa', alt: 'Принимается оплата через VISA' },
  { icon: 'mastercard', alt: 'Принимается оплата через MasterCard' },
  { icon: 'vtb', alt: 'Принимается оплата через ВТБ банк' },
  { icon: 'rosselhoz', alt: 'Принимается оплата через Россельхоз банк' },
  { icon: 'halva', alt: 'Принимается оплата через Халву' },
  { icon: 'sber', alt: 'Принимается оплата через СБП' },
  { icon: 'otkritie', alt: 'Принимается оплата через Банк Открытие' },
  { icon: 't-Bank', alt: 'Принимается оплата через T-Банк' },
  { icon: 'mir', alt: 'Принимается оплата через MIR' },
  { icon: 'psb', alt: 'Принимается оплата через Промсвязь банк' },
  { icon: 'smp', alt: 'Принимается оплата через СМП банк' },
  { icon: 'sovkombank', alt: 'Принимается оплата через Совкомбанк' },
  { icon: 'raifaisen', alt: 'Принимается оплата через Райффайзенбанк' },
];

type PaymentMethodsProps = {
  withBackground?: boolean;
};

const PaymentMethods: FC<PaymentMethodsProps> = ({ withBackground = false }) => {
  return (
    <div className={clsx(styles.payment_methods, withBackground ? styles.withBackground : '')}>
      <div className={styles.payment_methods__heading}>Принимаем оплату:</div>
      <ul className={styles.payment_methods__content}>
        {paymentData.map((item, i) => (
          <li key={i}>
            <Image
              src={`/static/icons/accept-payments/${item.icon}.svg`}
              alt={item.alt}
              loading="lazy"
              fill
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PaymentMethods;
