import { FC } from 'react';

import { Image } from '@/components/image';

import { SOCIAL_LINKS } from '@/constants/social.constant';

import styles from './Social.module.scss';

export const socialData = [
  {
    icon: '/static/icons/socials/instagram.svg',
    alt: 'Найдите нас в Instagram',
    href: SOCIAL_LINKS.instagram,
  },
  {
    icon: '/static/icons/socials/telegram.svg',
    alt: 'Найдите нас в Telegram',
    href: SOCIAL_LINKS.telegram,
  },
  { icon: '/static/icons/socials/vk.svg', alt: 'Найдите нас в Вконтакте', href: SOCIAL_LINKS.vk },
  {
    icon: '/static/icons/socials/yandex-dzen.svg',
    alt: 'Найдите нас в Yandex Dzen',
    href: SOCIAL_LINKS.dzen,
  },
];

const Social: FC = () => {
  return (
    <div className={styles.social}>
      <div className={styles.heading}>Мы в соцсетях:</div>
      <ul className={styles.list}>
        {socialData.map((social) => (
          <a
            key={social.icon}
            href={social.href}
            target="_blank"
            rel="noreferrer"
            className={styles.item}
          >
            <li>
              <Image src={social.icon} loading="lazy" fill alt={social.alt} />
            </li>
          </a>
        ))}
      </ul>
    </div>
  );
};

export default Social;
