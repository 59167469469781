import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useEffect } from 'react';

import { overlayVariants } from '@/components/ui/modals/motion-variants';

import { useGetRubricsState } from '@/store/api/rubricsApi';

import { MobileNavigation } from '../custom-drawer/mobile-navigation';

import styles from './MobileDrawer.module.scss';

type DrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

const drawerVariant = {
  initial: {
    x: '100%',
    transition: {
      type: 'spring',
      damping: 40,
      stiffness: 400,
    },
  },
  animate: {
    x: 0,
    transition: {
      type: 'spring',
      damping: 40,
      stiffness: 400,
    },
  },
  exit: {
    x: '100%',
    transition: {
      type: 'spring',
      damping: 40,
      stiffness: 400,
    },
  },
};

const MobileDrawer: FC<DrawerProps> = ({ onClose, isOpen }) => {
  const categoriesState = useGetRubricsState();

  useEffect(() => {
    isOpen ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll');
  }, [isOpen]);

  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <div className={styles.wrapper} key="modal">
          <motion.div
            className={styles.overlay}
            variants={overlayVariants}
            initial="hidden"
            animate="animate"
            exit="exit"
            onClick={onClose}
          />
          <motion.div
            className={clsx(styles.content)}
            variants={drawerVariant}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            {/*Основные компоненты*/}
            {/*     Внешний div для скроллинга при потере видимости меню*/}
            <div className={styles.body}>
              <div className={styles.close_drawer} onClick={onClose}>
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.3558 15.4681L24.2501 8.57377L25.7822 10.1058L18.8879 17.0001L25.7822 23.8944L24.2501 25.4265L17.3558 18.5322L10.4615 25.4265L8.92945 23.8944L15.8237 17.0001L8.92945 10.1058L10.4615 8.57377L17.3558 15.4681Z"
                    fill="#000"
                  />
                </svg>
              </div>

              <MobileNavigation
                categories={categoriesState?.data}
                loading={false}
                onClose={onClose}
              />
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default MobileDrawer;
