export const StarIcon = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.59997 13.0567L4.72663 15.39C4.55552 15.4989 4.37663 15.5456 4.18997 15.53C4.0033 15.5145 3.83997 15.4522 3.69997 15.3434C3.55997 15.2345 3.45108 15.0985 3.3733 14.9355C3.29552 14.7718 3.27997 14.5889 3.32663 14.3867L4.3533 9.97669L0.923301 7.01335C0.767746 6.87335 0.670679 6.71375 0.632101 6.53455C0.592901 6.35598 0.604412 6.18113 0.666635 6.01002C0.728857 5.83891 0.82219 5.69891 0.946635 5.59002C1.07108 5.48113 1.24219 5.41113 1.45997 5.38002L5.98663 4.98335L7.73663 0.83002C7.81441 0.643353 7.93512 0.503353 8.09877 0.41002C8.26179 0.316686 8.42886 0.27002 8.59997 0.27002C8.77108 0.27002 8.93846 0.316686 9.1021 0.41002C9.26512 0.503353 9.38552 0.643353 9.4633 0.83002L11.2133 4.98335L15.74 5.38002C15.9577 5.41113 16.1289 5.48113 16.2533 5.59002C16.3777 5.69891 16.4711 5.83891 16.5333 6.01002C16.5955 6.18113 16.6073 6.35598 16.5688 6.53455C16.5296 6.71375 16.4322 6.87335 16.2766 7.01335L12.8466 9.97669L13.8733 14.3867C13.92 14.5889 13.9044 14.7718 13.8266 14.9355C13.7489 15.0985 13.64 15.2345 13.5 15.3434C13.36 15.4522 13.1966 15.5145 13.01 15.53C12.8233 15.5456 12.6444 15.4989 12.4733 15.39L8.59997 13.0567Z"
      fill="#FFCA2C"
    />
  </svg>
);

export const ArrowIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6667 6L7.99999 10L3.33333 6"
      stroke="#1B1B1B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const QrIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2394_855)">
      <path
        d="M1.33334 11.2667C1.33334 10.3938 1.33334 9.95729 1.52982 9.63667C1.63976 9.45726 1.7906 9.30642 1.97001 9.19648C2.29064 9 2.7271 9 3.60001 9H4.33334C5.59042 9 6.21896 9 6.60949 9.39052C7.00001 9.78105 7.00001 10.4096 7.00001 11.6667V12.4C7.00001 13.2729 7.00001 13.7094 6.80353 14.03C6.69359 14.2094 6.54275 14.3602 6.36334 14.4702C6.04272 14.6667 5.60626 14.6667 4.73334 14.6667C3.42397 14.6667 2.76929 14.6667 2.28835 14.3719C2.01924 14.207 1.79297 13.9808 1.62806 13.7117C1.33334 13.2307 1.33334 12.576 1.33334 11.2667Z"
        stroke="#1B1B1B"
      />
      <path
        d="M9 3.59998C9 2.72706 9 2.29061 9.19648 1.96998C9.30642 1.79057 9.45726 1.63973 9.63667 1.52979C9.95729 1.33331 10.3938 1.33331 11.2667 1.33331C12.576 1.33331 13.2307 1.33331 13.7117 1.62803C13.9808 1.79294 14.207 2.0192 14.3719 2.28832C14.6667 2.76925 14.6667 3.42394 14.6667 4.73331C14.6667 5.60623 14.6667 6.04268 14.4702 6.36331C14.3602 6.54272 14.2094 6.69356 14.03 6.8035C13.7094 6.99998 13.2729 6.99998 12.4 6.99998H11.6667C10.4096 6.99998 9.78105 6.99998 9.39052 6.60946C9 6.21893 9 5.59039 9 4.33331V3.59998Z"
        stroke="#1B1B1B"
      />
      <path
        d="M11 4.16665C11 3.82304 11 3.65124 11.0861 3.52993C11.1164 3.48713 11.1538 3.44976 11.1966 3.41938C11.3179 3.33331 11.4897 3.33331 11.8333 3.33331C12.1769 3.33331 12.3487 3.33331 12.47 3.41938C12.5129 3.44976 12.5502 3.48713 12.5806 3.52993C12.6667 3.65124 12.6667 3.82304 12.6667 4.16665C12.6667 4.51025 12.6667 4.68205 12.5806 4.80336C12.5502 4.84616 12.5129 4.88354 12.47 4.91391C12.3487 4.99998 12.1769 4.99998 11.8333 4.99998C11.4897 4.99998 11.3179 4.99998 11.1966 4.91391C11.1538 4.88354 11.1164 4.84616 11.0861 4.80336C11 4.68205 11 4.51025 11 4.16665Z"
        fill="#1B1B1B"
      />
      <path
        d="M8.5 14.6667C8.5 14.9428 8.72386 15.1667 9 15.1667C9.27614 15.1667 9.5 14.9428 9.5 14.6667H8.5ZM9.59257 9.22471L9.87036 9.64044L9.87036 9.64044L9.59257 9.22471ZM9.22471 9.59257L8.80897 9.31479L8.80897 9.31479L9.22471 9.59257ZM12.6667 8.5H11.3333V9.5H12.6667V8.5ZM8.5 12.6667V14.6667H9.5V12.6667H8.5ZM11.3333 8.5C10.8756 8.5 10.4946 8.4994 10.1873 8.53066C9.8717 8.56277 9.57879 8.63257 9.31479 8.80897L9.87036 9.64044C9.94266 9.59213 10.052 9.54959 10.2886 9.52552C10.5335 9.5006 10.8548 9.5 11.3333 9.5V8.5ZM9.5 11.3333C9.5 10.8548 9.5006 10.5335 9.52552 10.2886C9.54959 10.052 9.59213 9.94266 9.64044 9.87036L8.80897 9.31479C8.63257 9.57879 8.56277 9.8717 8.53066 10.1873C8.4994 10.4946 8.5 10.8756 8.5 11.3333H9.5ZM9.31479 8.80897C9.11461 8.94273 8.94273 9.11461 8.80897 9.31479L9.64044 9.87036C9.70124 9.77937 9.77937 9.70124 9.87036 9.64044L9.31479 8.80897Z"
        fill="#1B1B1B"
      />
      <path
        d="M15.1667 9C15.1667 8.72386 14.9428 8.5 14.6667 8.5C14.3905 8.5 14.1667 8.72386 14.1667 9H15.1667ZM13.8436 14.5652L14.0349 15.0271L14.0349 15.0271L13.8436 14.5652ZM14.5652 13.8436L14.1032 13.6522H14.1032L14.5652 13.8436ZM11.3333 15.1667H12.6667V14.1667H11.3333V15.1667ZM15.1667 11.3333V9H14.1667V11.3333H15.1667ZM12.6667 15.1667C12.9705 15.1667 13.2233 15.1669 13.4303 15.1528C13.6417 15.1384 13.8413 15.1073 14.0349 15.0271L13.6522 14.1032C13.6008 14.1245 13.5227 14.1442 13.3622 14.1551C13.1972 14.1664 12.9841 14.1667 12.6667 14.1667V15.1667ZM14.1667 12.6667C14.1667 12.9841 14.1664 13.1972 14.1551 13.3622C14.1442 13.5227 14.1245 13.6008 14.1032 13.6522L15.0271 14.0349C15.1073 13.8413 15.1384 13.6416 15.1528 13.4302C15.1669 13.2233 15.1667 12.9705 15.1667 12.6667H14.1667ZM14.0349 15.0271C14.4841 14.841 14.8411 14.4841 15.0271 14.0349L14.1032 13.6522C14.0187 13.8564 13.8564 14.0187 13.6522 14.1032L14.0349 15.0271Z"
        fill="#1B1B1B"
      />
      <path
        d="M1.33334 4.73331C1.33334 3.42394 1.33334 2.76925 1.62806 2.28832C1.79297 2.0192 2.01924 1.79294 2.28835 1.62803C2.76929 1.33331 3.42397 1.33331 4.73334 1.33331C5.60626 1.33331 6.04272 1.33331 6.36334 1.52979C6.54275 1.63973 6.69359 1.79057 6.80353 1.96998C7.00001 2.29061 7.00001 2.72706 7.00001 3.59998V4.33331C7.00001 5.59039 7.00001 6.21893 6.60949 6.60946C6.21896 6.99998 5.59042 6.99998 4.33334 6.99998H3.60001C2.7271 6.99998 2.29064 6.99998 1.97001 6.8035C1.7906 6.69356 1.63976 6.54272 1.52982 6.36331C1.33334 6.04268 1.33334 5.60623 1.33334 4.73331Z"
        stroke="#1B1B1B"
      />
      <path
        d="M3.33334 4.16665C3.33334 3.82304 3.33334 3.65124 3.41942 3.52993C3.44979 3.48713 3.48716 3.44976 3.52996 3.41938C3.65127 3.33331 3.82307 3.33331 4.16668 3.33331C4.51028 3.33331 4.68208 3.33331 4.80339 3.41938C4.84619 3.44976 4.88357 3.48713 4.91394 3.52993C5.00001 3.65124 5.00001 3.82304 5.00001 4.16665C5.00001 4.51025 5.00001 4.68205 4.91394 4.80336C4.88357 4.84616 4.84619 4.88354 4.80339 4.91391C4.68208 4.99998 4.51028 4.99998 4.16668 4.99998C3.82307 4.99998 3.65127 4.99998 3.52996 4.91391C3.48716 4.88354 3.44979 4.84616 3.41942 4.80336C3.33334 4.68205 3.33334 4.51025 3.33334 4.16665Z"
        fill="#1B1B1B"
      />
      <path
        d="M3.33334 11.8333C3.33334 11.4897 3.33334 11.3179 3.41942 11.1966C3.44979 11.1538 3.48716 11.1164 3.52996 11.0861C3.65127 11 3.82307 11 4.16668 11C4.51028 11 4.68208 11 4.80339 11.0861C4.84619 11.1164 4.88357 11.1538 4.91394 11.1966C5.00001 11.3179 5.00001 11.4897 5.00001 11.8333C5.00001 12.1769 5.00001 12.3487 4.91394 12.47C4.88357 12.5129 4.84619 12.5502 4.80339 12.5806C4.68208 12.6667 4.51028 12.6667 4.16668 12.6667C3.82307 12.6667 3.65127 12.6667 3.52996 12.5806C3.48716 12.5502 3.44979 12.5129 3.41942 12.47C3.33334 12.3487 3.33334 12.1769 3.33334 11.8333Z"
        fill="#1B1B1B"
      />
      <path
        d="M10.6667 11.8334C10.6667 11.3652 10.6667 11.1311 10.779 10.963C10.8276 10.8902 10.8901 10.8277 10.9629 10.779C11.1311 10.6667 11.3652 10.6667 11.8333 10.6667C12.3015 10.6667 12.5356 10.6667 12.7037 10.779C12.7765 10.8277 12.839 10.8902 12.8876 10.963C13 11.1311 13 11.3652 13 11.8334C13 12.3015 13 12.5356 12.8876 12.7037C12.839 12.7765 12.7765 12.839 12.7037 12.8877C12.5356 13 12.3015 13 11.8333 13C11.3652 13 11.1311 13 10.9629 12.8877C10.8901 12.839 10.8276 12.7765 10.779 12.7037C10.6667 12.5356 10.6667 12.3015 10.6667 11.8334Z"
        fill="#1B1B1B"
      />
    </g>
    <defs>
      <clipPath id="clip0_2394_855">
        <rect width="16" height="16" rx="5" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
