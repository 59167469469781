import useOutsideClick from '@rooks/use-outside-click';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import { Skeleton } from '@/components/ui/loaders/Skeletons/Skeletons';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useTypedSelector } from '@/hooks/useTypedSelector';

import {
  timeExpressOptions,
  timeOptions,
} from '@/pages/Checkout/basket-form/forms/delivery-form/time-options.data';

import { useGetCitiesQuery } from '@/store/api/cities.api';
import { changeCity } from '@/store/slices/city/city.slice';

import { ICityNew } from '@/types/new/cities';

import { LS_CURRENT_SELECTED_CITY_ID } from '@/constants/local-storage.constant';

import styles from './HeaderCities.module.scss';

const geoIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.66666 6.76221C2.66666 3.76393 5.05447 1.33334 7.99999 1.33334C10.9455 1.33334 13.3333 3.76393 13.3333 6.76221C13.3333 9.73699 11.6311 13.2083 8.97528 14.4496C8.35616 14.739 7.64382 14.739 7.0247 14.4496C4.36887 13.2083 2.66666 9.73699 2.66666 6.76221Z"
      stroke="#202020"
      strokeWidth="1.5"
    />
    <ellipse cx="8" cy="6.66666" rx="2" ry="2" stroke="#202020" strokeWidth="1.5" />
  </svg>
);

const chevronDownIcon = (
  <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4688 0.969727L13.5294 2.03039L6.99908 8.56072L0.46875 2.03039L1.52941 0.969727L6.99908 6.4394L12.4688 0.969727Z"
      fill="#131313"
    />
  </svg>
);

const HeaderCities = ({ noIcon }: { noIcon?: boolean }) => {
  const { data: cities, isLoading } = useGetCitiesQuery();
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const selectRef = useRef<any>();
  const router = useRouter();
  const isDisabled = router.pathname === '/checkout' || (cities && cities.length <= 1);

  const dispatch = useAppDispatch();

  const { city, cityRegion } = useTypedSelector((state) => state.city);

  const toggleDropdown = () => {
    if (isDisabled) return;
    setIsOpenDropdown(!isOpenDropdown);
  };

  const changeCityHandler = useCallback(
    (city: ICityNew) => {
      setIsOpenDropdown(false);
      const { name, region, max_delivery_area } = city;
      const currentShop = city.shops_city_links ? city.shops_city_links[0].shops : null;

      dispatch(
        changeCity({
          cityId: city.id,
          name,
          cityRegion: region,
          max_delivery_area,
          cityPickupPoint: currentShop?.address ?? '',
          timezoneUTC: city.timezone_utc ?? 0,
          default_delivery_intervals: currentShop?.default_delivery_intervals ?? timeOptions,
          express_delivery_intervals: currentShop?.express_delivery_intervals ?? timeExpressOptions,
          precise_time_delivery_intervals:
            currentShop?.precise_time_delivery_intervals ?? timeExpressOptions,
          pickup_latitude: currentShop?.point.latitude ?? 0,
          pickup_longitude: currentShop?.point.longitude ?? 0,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (!cities) return;
    const savedCityId = localStorage.getItem(LS_CURRENT_SELECTED_CITY_ID);

    if (
      savedCityId &&
      cities &&
      cities.findIndex((city) => city.id === Number(savedCityId)) !== -1
    ) {
      const city = cities.find((city) => city.id === Number(savedCityId));
      changeCityHandler(city as ICityNew);
    } else {
      changeCityHandler(cities[0]);
    }
  }, [changeCityHandler, cities]);

  useOutsideClick(selectRef, () => setIsOpenDropdown(false));

  if (isLoading) return <Skeleton className={styles.skeleton} />;

  return (
    <div
      className={clsx(noIcon ? styles.geo_no_icon : styles.geo)}
      ref={selectRef}
      data-testid="geo-toggler"
      onClick={toggleDropdown}
    >
      {!noIcon && geoIcon}

      <div className={clsx(noIcon ? styles.geo__text_no_icon : styles.geo__text)}>
        <Fragment>
          <span>
            {city}, {cityRegion[0]}О
          </span>
          {isOpenDropdown && (
            <motion.ul
              initial={{ y: '-30px' }}
              animate={{ y: '0' }}
              exit={{ opacity: 0 }}
              className={clsx(noIcon ? styles.geo__dropdown_no_icon : styles.geo__dropdown)}
            >
              {cities?.map((cityEl) => (
                <li
                  key={cityEl.id}
                  className={styles.geo__dropdown_text}
                  data-testid="geo-item"
                  onClick={() => changeCityHandler(cityEl)}
                >
                  <p>{`${cityEl.name} и ${cityEl.region} область`}</p>
                  {cityEl.name === city && (
                    <div className={styles.geo__dropdown_icon}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30 30"
                        width="30px"
                        height="30px"
                      >
                        <path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z" />
                      </svg>
                    </div>
                  )}
                </li>
              ))}
            </motion.ul>
          )}
        </Fragment>
      </div>
      <div
        className={clsx(
          styles.geo__chevron_icon,
          isOpenDropdown && styles.geo__chevron_icon_active
        )}
        hidden={isDisabled}
      >
        {chevronDownIcon}
      </div>
    </div>
  );
};

export default HeaderCities;
