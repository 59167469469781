import clsx from 'clsx';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { forwardRef } from 'react';

import { StarIcon } from '@/components/layout/icons/other';
import {
  InstaIcon,
  OdnoklassnikiIcon,
  TgIcon,
  VkIcon,
  YouTubeIcon,
} from '@/components/layout/icons/sosials';

import { analyticMetric } from '@/helpers/analytics-metric';
import { formatPhoneNumber } from '@/helpers/string/formatPhoneNumber';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { changeModalVisibility } from '@/store/slices/support-modal/support-modal.slice';

import { ROUTES } from '@/constants/routes.constant';
import { SOCIAL_LINKS } from '@/constants/social.constant';

import stl from './HeaderTop.module.scss';

type MenuItem =
  | {
      text: string;
      href: string;
      onClick?: never;
      target?: string;
    }
  | {
      text: string;
      href?: never;
      onClick: () => void;
    };

const socials = [
  { icon: VkIcon, url: SOCIAL_LINKS.vk },
  { icon: InstaIcon, url: SOCIAL_LINKS.instagram },
  { icon: TgIcon, url: SOCIAL_LINKS.telegram },
  { icon: YouTubeIcon, url: SOCIAL_LINKS.youtube },
  { icon: OdnoklassnikiIcon, url: SOCIAL_LINKS.odnoklassniki },
];

// eslint-disable-next-line react/display-name
const HeaderTop = forwardRef<HTMLDivElement, { className?: string }>(({ className }, ref) => {
  const dispatch = useAppDispatch();

  const topMenu: MenuItem[] = [
    { text: 'О нас', href: ROUTES.ABOUT_US },
    { text: 'Доставка', href: ROUTES.DELIVERY },
    { text: 'Оплата', href: ROUTES.PAYMENT },
    { text: 'Контакты', href: ROUTES.CONTACTS },
    { text: 'Поддержка', onClick: () => dispatch(changeModalVisibility(true)) },
    { text: 'Корп. клиентам', href: ROUTES.KORPORATIVNYM_KLIENTAM },
    { text: 'Блог', href: ROUTES.BLOG, target: '_blank' },
  ];

  return (
    <div className={className} ref={ref}>
      <div className={stl.container}>
        <div className={stl.rating}>
          <a href={ROUTES.REVIEWS} className={stl.rating__reviews}>
            Отзывы
          </a>
          <div className={stl.rating__icon}>
            <StarIcon />
          </div>
          <p className={stl.rating__data}>5.0</p>
          <a
            href={`tel:${formatPhoneNumber(SOCIAL_LINKS.main_phone_number)}`}
            className={stl.rating__phone}
            onClick={() => analyticMetric.hitGoal('klik-po-nomeru-telefona-v-shapke')}
          >
            {formatPhoneNumber(SOCIAL_LINKS.main_phone_number, { mode: 'onlySpaces' })}
          </a>
          <a
            href={`tel:${formatPhoneNumber(SOCIAL_LINKS.additional_phone_number)}`}
            className={stl.rating__phone}
            onClick={() => analyticMetric.hitGoal('klik-po-nomeru-telefona-v-shapke')}
          >
            {formatPhoneNumber(SOCIAL_LINKS.additional_phone_number, { mode: 'onlySpaces' })}
          </a>
        </div>
        <nav className={stl.menu}>
          {topMenu.map((el) => {
            if (el.href) {
              return (
                <Link
                  key={el.href}
                  href={el.href}
                  target={el.target || '_self'}
                  className={stl.menu_link}
                >
                  {el.text}
                </Link>
              );
            }

            if (el.onClick) {
              return (
                <button key={el.href || el.text} className={stl.menu_link} onClick={el.onClick}>
                  {el.text}
                </button>
              );
            }
          })}
        </nav>
        <div className={clsx(stl.socials, className)}>
          {socials.map((link) => (
            <a key={link.url} href={link.url} className={stl.socials_link} target="_blank">
              <link.icon />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
});

export const MotionHeaderTop = motion(HeaderTop);

export default HeaderTop;
