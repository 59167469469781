import useOutsideClick from '@rooks/use-outside-click';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';

import DropdownRecommend from '@/components/search-bar/dropdown-recommend/DropdownRecommend';
import SearchDropdown from '@/components/search-bar/search-dropdown/SearchDropdown';

import { analyticMetric } from '@/helpers/analytics-metric';

import { useDebounce } from '@/hooks/useDebounced';
import { useMediaQuery } from '@/hooks/useMediaQuery';

import { ProductService } from '@/services/product.service';

import { IProductNew } from '@/types/new/products';

import styles from './SearchBar.module.scss';

const SEARCH_LIMIT = 15;
const DEBOUNCE_TIME_MS = 1000;

export const SearchBar = ({ className }: { className?: string }) => {
  const [suggestions, setSuggestions] = useState<IProductNew[]>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [focused, setFocused] = useState(false);
  const searchWrapperRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const debounceSearch = useDebounce(searchTerm.toString().trim(), DEBOUNCE_TIME_MS);
  const isMobile = useMediaQuery('480px', 'max-width');

  const handlerOnSearchOutsideClick = () => {
    unblockScroll();
    if (focused) setFocused(false);
    if (searchTerm) setSearchTerm('');
    if (suggestions && suggestions.length > 0) setSuggestions([]);
  };

  useOutsideClick(searchWrapperRef as any, handlerOnSearchOutsideClick);

  const searchResponse = async () => {
    try {
      const { data } = await ProductService.getBySearchQuery(
        debounceSearch,
        undefined,
        SEARCH_LIMIT
      );

      setSuggestions(data.hits);
    } catch (e) {
      console.log(e);
    }
  };

  const handlerOnChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handlerOnSubmit = async (e: FormEvent) => {
    analyticMetric.hitGoal('poisk:-nazhatie-na-knopku-najti-libo-enter');

    e.preventDefault();
    setSearchTerm('');
    setFocused(false);
    setSuggestions([]);

    if (searchTerm.trim()) {
      await router.push(`/search?term=${encodeURIComponent(searchTerm.trim())}`);
    }
  };

  useEffect(() => {
    if (focused) {
      router.events.on('routeChangeComplete', () => {
        setFocused(false);
      });
    }
  }, [router.events, focused, setFocused]);

  useEffect(() => {
    (function () {
      if (debounceSearch.toString().length > 1) {
        return searchResponse();
      }
      return setSuggestions([]);
    })();
  }, [debounceSearch]);

  const blockScroll = () => {
    window.document.body.classList.add('no-scroll');
  };

  const unblockScroll = () => {
    window.document.body.classList.remove('no-scroll');
  };

  return (
    <form className={clsx(styles.form, className)} onSubmit={handlerOnSubmit}>
      <div
        className={clsx(
          styles.wrapper,
          focused || searchTerm.length > 0 ? styles.focused_input : ''
        )}
        ref={searchWrapperRef}
      >
        <div className={styles.container}>
          <label className={styles.label}>
            <input
              value={searchTerm}
              type="text"
              className={styles.input}
              placeholder={isMobile ? 'Поиск в AzaliaNow' : 'Поиск цветов в AzaliaNow'}
              inputMode="search"
              maxLength={100}
              onChange={handlerOnChange}
              onFocus={() => setFocused(true)}
              onBlur={() => setTimeout(() => setFocused(false), 200)}
            />
            {searchTerm.length >= 1 && (
              <div className={styles.remove} onClick={() => setSearchTerm('')}>
                <RemoveSearchIcon />
              </div>
            )}
          </label>
          <div className={styles.search_btn} onClick={handlerOnSubmit}>
            <SearchIcon />
          </div>
        </div>
        {focused && Boolean(!suggestions?.length) && (
          <DropdownRecommend onItemClick={handlerOnSearchOutsideClick} />
        )}
        {!!suggestions?.length && searchTerm && (
          <SearchDropdown
            suggestions={suggestions}
            searchTerm={searchTerm}
            onItemClick={handlerOnSearchOutsideClick}
            onMouseEnter={blockScroll}
            onMouseLeave={unblockScroll}
          />
        )}
      </div>
    </form>
  );
};

export const SearchIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11.5" cy="11.5" r="9.5" stroke="white" strokeWidth="1.5" />
    <path d="M18.5 18.5L22 22" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export const RemoveSearchIcon = () => (
  <svg viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5" clipPath="url(#clip0_218_6295)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3558 15.4679L24.2501 8.57364L25.7822 10.1057L18.8879 17L25.7822 23.8943L24.2501 25.4264L17.3558 18.5321L10.4615 25.4264L8.92945 23.8943L15.8237 17L8.92945 10.1057L10.4615 8.57364L17.3558 15.4679Z"
        fill="#6B6B6B"
      />
    </g>
    <defs>
      <clipPath id="clip0_218_6295">
        <rect width="33.9411" height="33.9411" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SearchBar;
