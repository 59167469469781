export const timeOptions = [
  { value: '09:00 - 13:00', label: 'с 09:00 до 15:00' },
  { value: '10:00 - 14:00', label: 'с 10:00 до 16:00' },
  { value: '11:00 - 15:00', label: 'с 11:00 до 17:00' },
  { value: '12:00 - 16:00', label: 'с 12:00 до 18:00' },
  { value: '13:00 - 17:00', label: 'с 13:00 до 19:00' },
  { value: '14:00 - 18:00', label: 'с 14:00 до 20:00' },
  { value: '15:00 - 19:00', label: 'с 15:00 до 21:00' },
  { value: '16:00 - 20:00', label: 'с 16:00 до 22:00' },
  { value: '17:00 - 21:00', label: 'с 17:00 до 23:00' },
  { value: '18:00 - 22:00', label: 'с 18:00 до 24:00' },
  { value: '19:00 - 23:00', label: 'с 19:00 до 01:00' },
  { value: '20:00 - 00:00', label: 'с 20:00 до 02:00' },
];

export const timeExpressOptions = [
  { value: '06:00 - 08:00', label: 'с 06:00 до 08:00' },
  { value: '08:00 - 10:00', label: 'с 08:00 до 10:00' },
  { value: '10:00 - 12:00', label: 'с 10:00 до 12:00' },
  { value: '12:00 - 14:00', label: 'с 12:00 до 14:00' },
  { value: '14:00 - 16:00', label: 'с 14:00 до 16:00' },
  { value: '16:00 - 18:00', label: 'с 16:00 до 18:00' },
  { value: '18:00 - 20:00', label: 'с 18:00 до 20:00' },
  { value: '20:00 - 22:00', label: 'с 20:00 до 22:00' },
  { value: '22:00 - 00:00', label: 'с 22:00 до 00:00' },
];
